<template>
  <div>
    <v-card>
      <v-card-title>定期便注文スケジュール編集</v-card-title>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-0 pt-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員ID</label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="selectedScheduled.mbr_id"
            readonly
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員氏名</label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="SchSubForm.mbr_name"
            readonly
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-2 ma-0 py-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>送付先<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.SchSubForm.addr_name.$model"
            :error-messages="subAddrNameErrors"
            readonly
            outlined
            dense
            placeholder="送付先"
            hide-details="auto"
          ></v-text-field>
          <p style="font-size:14px;margin-top:2px;margin-bottom: 0px;">
            {{ selectedScheduled.addr_addr1 }} {{ selectedScheduled.addr_addr2 }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <Addresses
            :mbr-id="selectedScheduled.mbr_id"
            :addr-id-list="addrIdList"
            @add_adrlist="getaddAdrList"
          ></Addresses>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 pt-1">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">定期便</label>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <span>{{ selectedScheduled.subs_name }}</span>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-2 pb-3">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">注文時日</label>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <span>{{ selectedScheduled.order_dt }}</span>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">決済予定日</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-0 my-0"
        >
          <v-menu
            v-model="dateStartMenuPud"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="SchSubForm.sch_pud"
                :error-messages="subPudErrors"
                placeholder="決済予定日"
                color="#fa7a00"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="SchSubForm.sch_pud"
              color="#fa7a00"
              @input="dateStartMenuPud = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">発送予定日</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-0 my-0"
        >
          <v-menu
            v-model="dateStartMenuEad"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="SchSubForm.sch_ead"
                :error-messages="subEadErrors"
                placeholder="発送予定日"
                color="#fa7a00"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="SchSubForm.sch_ead"
              color="#fa7a00"
              @input="dateStartMenuEad = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>定期便状態<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-autocomplete
            v-model="SchSubForm.sch_status_code"
            :items="subStatuses"
            outlined
            dense
            placeholder="定期便状態"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <label for="note">備考</label>
          </v-col>
          <v-col
            cols="12"
            md="11"
          >
            <v-textarea
              v-model="$v.SchSubForm.ssop_proc_memo.$model"
              :error-messages="memoErrors"
              name="note"
              hide-details="auto"
              outlined
              :counter="100"
              dense
              rows="5"
              placeholder="備考"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mr-5"
          :loading="submitStatus"
          @click="submit(SchSubForm)"
        >
          <span>保存</span>
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="cancelClick"
        >
          <span>キャンセル</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'

import Addresses from '@/components/Addresses.vue'

export default {
  components: {
    Addresses,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
    SchSubForm: {
      addr_id: '',
      sch_ead: '',
      sch_pud: '',
      sch_status_code: '',
      ssop_proc_memo: '',
    },
    dateStartMenuPud: '',
    dateStartMenuEad: '',
    subStatuses: [],
    addrIdList: [],
    changeFlag: false,
  }),
  validations: {
    SchSubForm: {
      addr_name: {
        required,
      },
      sch_pud: {
        required,
      },
      sch_ead: {
        required,
      },
      ssop_proc_memo: {
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    ...mapState('scheduledSubsStore', ['SubsStatusList', 'selectedScheduled']),

    subAddrNameErrors() {
      const errors = []
      if (!this.$v.SchSubForm.addr_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.SchSubForm.addr_name.required && errors.push('必須項目')

      return errors
    },
    subPudErrors() {
      const errors = []
      if (!this.$v.SchSubForm.sch_pud.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.SchSubForm.sch_pud.required && errors.push('必須項目')

      return errors
    },
    subEadErrors() {
      const errors = []
      if (!this.$v.SchSubForm.sch_ead.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.SchSubForm.sch_ead.required && errors.push('必須項目')

      return errors
    },
    memoErrors() {
      const errors = []
      if (!this.$v.SchSubForm.ssop_proc_memo.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.SchSubForm.ssop_proc_memo.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
  },
  watch: {
    SchSubForm: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('scheduledSubsStore', ['loadSubsStatusList', 'loadScheduled', 'editScheduled']),
    ...mapMutations('scheduledSubsStore', ['clearSelectedScheduled']),
    getaddAdrList(addAdrList) {
      console.log('addAdrList', addAdrList)
      this.addrIdList = []
      if (addAdrList.length === 1) {
        this.SchSubForm.addr_id = addAdrList[0].addr_id
        this.SchSubForm.addr_name = addAdrList[0].addr_sei + addAdrList[0].addr_mei
        this.selectedScheduled.addr_addr1 = addAdrList[0].addr_addr1
        this.selectedScheduled.addr_addr2 = addAdrList[0].addr_addr2
        this.selectedScheduled.addr_zip = addAdrList[0].addr_zip
        this.selectedScheduled.prefcode = addAdrList[0].prefcode
        this.addrIdList.push({
          addr_id: addAdrList[0].addr_id,
        })
        console.log(this.SchSubForm.addr_name)
      } else {
        this.SchSubForm.addr_id = ''
        this.SchSubForm.addr_name = ''
        this.selectedScheduled.addr_addr1 = ''
        this.selectedScheduled.addr_addr2 = ''
        this.selectedScheduled.addr_zip = ''
        this.selectedScheduled.prefcode = ''
      }
    },

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadScheduled(this.$route.params.id),
        this.loadSubsStatusList(),
      ]).then(() => {
        this.getStatuItem()
        this.addrIdList = []
        this.addrIdList.push({
          addr_id: this.selectedScheduled.addr_id,
        })
        this.SchSubForm.sch_id = this.selectedScheduled.sch_id
        this.SchSubForm.addr_id = this.selectedScheduled.addr_id
        this.SchSubForm.sch_status_code = this.selectedScheduled.sch_status_code
        this.SchSubForm.sch_ead = this.selectedScheduled.sch_ead
        this.SchSubForm.sch_pud = this.selectedScheduled.sch_pud
        this.SchSubForm.mbr_name = this.selectedScheduled.mbr_sei + this.selectedScheduled.mbr_mei
        this.SchSubForm.addr_name = this.selectedScheduled.addr_sei + this.selectedScheduled.addr_mei
      }).catch(error => {
        console.log('loadData', error)
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.changeFlag = false
        this.setOverlayStatus(false)
      })
    },

    jumpToListPage() {
      this.$router.push({
        path: '/scheduledsubscription-list',
      })
    },

    submit(SchSubForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        Promise.all([
          this.editScheduled(SchSubForm),
        ]).then(() => {
          this.jumpToListPage()
        }).catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.submitStatus = false
        })
      } else {
        console.log('error submit!!:', SchSubForm)
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
      }
    },

    getStatuItem() {
      this.subStatuses = this.SubsStatusList.map(x => {
        const obj = {
        }
        obj.text = `${x.subs_status_code}: ${x.subs_status_desc}`
        obj.value = x.subs_status_code

        return obj
      })
    },
  },
}
</script>

<style scoped>
#couponPriceColor {
  color: red !important;
}
</style>
